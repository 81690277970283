import React, { useEffect, useState } from "react";
import { useStripeConnect } from "./hooks/useStripeConnect";
import {
    ConnectAccountOnboarding,
    ConnectComponentsProvider,
} from "@stripe/react-connect-js";

export default function Home() {
    const [accountCreatePending, setAccountCreatePending] = useState(false);
    const [onboardingExited, setOnboardingExited] = useState(false);
    const [error, setError] = useState(false);
    const [connectedAccountId, setConnectedAccountId] = useState();
    const stripeConnectInstance = useStripeConnect(connectedAccountId);
    const [showConnectedMessage, setShowConnectedMessage] = useState(false);

    useEffect(() => {
        setShowConnectedMessage(true);
        const timer = setTimeout(() => {
            setShowConnectedMessage(false); // Clear the account ID after 5 seconds
        }, 5000);
        return () => clearTimeout(timer); // Cleanup on unmount
    }, [connectedAccountId]);

    return (
        <div className="container">
            <div className="banner">
                <h2>CharityStack</h2>
            </div>
            <div className="content">
                {!connectedAccountId && <h2>Get ready to start raising</h2>}
                {connectedAccountId && !stripeConnectInstance && (
                    <h2>Add information to start accepting money</h2>
                )}
                {!connectedAccountId && (
                    <p>
                        CharityStack is here to be the financial operation
                        system for your nonprofit.
                    </p>
                )}
                {!accountCreatePending && !connectedAccountId && (
                    <div>
                        <button
                            onClick={async () => {
                                setAccountCreatePending(true);
                                setError(false);
                                fetch(
                                    `${process.env.REACT_APP_BACKEND_URL}/account`,
                                    {
                                        method: "POST",
                                    }
                                )
                                    .then((response) => response.json())
                                    .then((json) => {
                                        setAccountCreatePending(false);
                                        const { account, error } = json;

                                        if (account) {
                                            setConnectedAccountId(account);
                                        }

                                        if (error) {
                                            setError(true);
                                        }
                                    });
                            }}>
                            Sign up
                        </button>
                    </div>
                )}
                {stripeConnectInstance && (
                    <ConnectComponentsProvider
                        connectInstance={stripeConnectInstance}>
                        <ConnectAccountOnboarding
                            onExit={() => setOnboardingExited(true)}
                            privacyPolicyUrl="https://charitystack.com/privacy"
                            fullTermsOfServiceUrl="https://www.charitystack.com/terms-of-service"
                        />
                    </ConnectComponentsProvider>
                )}
                {error && <p className="error">Something went wrong!</p>}
                {(connectedAccountId ||
                    accountCreatePending ||
                    onboardingExited) && (
                    <div className="flex items-center justify-center">
                        {showConnectedMessage && connectedAccountId && (
                            <div className="dev-callout">
                                <p>
                                    {/* Your connected account ID is:{" "}
                                <code className="bold">
                                    {connectedAccountId}
                                </code> */}
                                    Your connected account ID has been
                                    generated. Please proceed to the next step.
                                </p>
                            </div>
                        )}
                        {accountCreatePending && (
                            <div className="dev-callout">
                                <p>Creating a connected account...</p>
                            </div>
                        )}
                        {onboardingExited && (
                            <div className="dev-callout">
                                <p>
                                    The Account Onboarding component has exited
                                </p>
                            </div>
                        )}
                    </div>
                )}
                {/* <div className="info-callout">
                    <p>
                        This is a sample app for Connect onboarding using the
                        Account Onboarding embedded component.{" "}
                        <a
                            href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=embedded"
                            target="_blank"
                            rel="noopener noreferrer">
                            View docs
                        </a>
                    </p>
                </div> */}
            </div>
        </div>
    );
}
